var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aideRoleComponent"},[_c('CRow',[_c('CCol',[_c('p',[_vm._v(" Pour vous permettre de gérer au mieux votre activité, Eclerk vous permet de créer des collaborateurs ayant différents rôles. Ces rôles se partagent entre "),_c('em',[_c('CBadge',{attrs:{"color":"dark","shape":"pill"}},[_vm._v("Collaborateur")]),_vm._v(", "),_c('CBadge',{attrs:{"color":"danger","shape":"pill"}},[_vm._v("Manager")]),_vm._v(", "),_c('CBadge',{attrs:{"color":"primary","shape":"pill"}},[_vm._v("Administrateur")]),_vm._v(", "),_c('CBadge',{attrs:{"color":"success","shape":"pill"}},[_vm._v("Superadministrateur")])],1),_vm._v(" et sont cumulatifs."),_c('br')]),_c('p',[_vm._v(" Les utilisateurs \"Collaborateur\" peuvent seulement gérer leur activité. Les utilisateurs \"Manager\" peuvent gérer la facturation, visualiser le reporting du cabinet, créer des missions et s'occuper de l'affectation des collaborateurs. Les utilisateurs \"Administrateur\" peuvent créer des utilisateurs et gérer les types de mission du cabinet. ")])])],1),_c('CDataTable',{attrs:{"items":_vm.permissionsRender,"fields":_vm.permissionsRenderFields},scopedSlots:_vm._u([{key:"collaborateur",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.collaborateur)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cil-check"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cil-x-circle"}})],1)]}},{key:"manager",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.manager)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cil-check"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cil-x-circle"}})],1)]}},{key:"admin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.admin)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cil-check"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cil-x-circle"}})],1)]}},{key:"superadmin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.superadmin)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cil-check"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cil-x-circle"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }