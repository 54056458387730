<template>
  <div class="aideRoleComponent">
    <CRow>
      <CCol>
        <p>
          Pour vous permettre de gérer au mieux votre activité, Eclerk vous permet de créer des collaborateurs ayant différents rôles. 
          Ces rôles se partagent entre 
          <em>
            <CBadge color="dark" shape="pill">Collaborateur</CBadge>, 
            <CBadge color="danger" shape="pill">Manager</CBadge>, 
            <CBadge color="primary" shape="pill">Administrateur</CBadge>, 
            <CBadge color="success" shape="pill">Superadministrateur</CBadge> </em> et sont cumulatifs.<br>
        </p>
        <p>
          Les utilisateurs "Collaborateur" peuvent seulement gérer leur activité. Les utilisateurs "Manager" peuvent gérer la facturation, visualiser le reporting du cabinet, créer des missions 
          et s'occuper de l'affectation des collaborateurs. Les utilisateurs "Administrateur" peuvent créer des utilisateurs et gérer les types de mission du cabinet. 
        </p>
      </CCol>
    </CRow>
    
    <CDataTable
        :items="permissionsRender"
        :fields="permissionsRenderFields"
      >
      <template #collaborateur="{item}">
        <td class="text-center">
          <CIcon class="text-success" name="cil-check" v-if="item.collaborateur" />
          <CIcon class="text-danger" name="cil-x-circle" v-else />
        </td>
      </template>
      
      <template #manager="{item}">
        <td class="text-center">
          <CIcon class="text-success" name="cil-check" v-if="item.manager" />
          <CIcon class="text-danger" name="cil-x-circle" v-else />
        </td>
      </template>
      
      <template #admin="{item}">
        <td class="text-center">
          <CIcon class="text-success" name="cil-check" v-if="item.admin" />
          <CIcon class="text-danger" name="cil-x-circle" v-else />
        </td>
      </template>
      
      <template #superadmin="{item}">
        <td class="text-center">
          <CIcon class="text-success" name="cil-check" v-if="item.superadmin" />
          <CIcon class="text-danger" name="cil-x-circle" v-else />
        </td>
      </template>

    </CDataTable>

  </div>
</template>
<script>

export default {
  name: 'aideRoleComponent',
  
  data: function () {
    return {
      // ------------- MISSION -----------
      permissionsRender: [
        {
          'permission_name': 'Créer, éditer et archiver des clients',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Créer, éditer et archiver des contacts',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Renseigner son reporting journalier',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Visualiser les missions sur lesquels il est affecté',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Créer, éditer et archiver des priorités missions',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Gérer la logistique du cabinet',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Editer son profil',
          'collaborateur': true,
          'manager': true,
          'admin': true,
          'superadmin': true
        },
        {
          'permission_name': 'Créer, éditer et archiver des missions',
          'collaborateur': false,
          'manager': true,
          'admin': false,
          'superadmin': false
        },
        {
          'permission_name': "Editer la convention d'honoraires des missions",
          'collaborateur': false,
          'manager': true,
          'admin': false,
          'superadmin': false
        },
        {
          'permission_name': 'Créer, éditer et émettre des factures',
          'collaborateur': false,
          'manager': true,
          'admin': false,
          'superadmin': false
        },
        {
          'permission_name': "Visualiser l'état de la facturation",
          'collaborateur': false,
          'manager': true,
          'admin': false,
          'superadmin': false
        },
        {
          'permission_name': "Visualiser le reporting cabinet",
          'collaborateur': false,
          'manager': true,
          'admin': false,
          'superadmin': false
        },
        {
          'permission_name': "Accéder aux archives clients, contacts et missions",
          'collaborateur': false,
          'manager': true,
          'admin': false,
          'superadmin': false
        },
        
        {
          'permission_name': "Ajouter, éditer et supprimer des collaborateurs",
          'collaborateur': false,
          'manager': false,
          'admin': true,
          'superadmin': false
        },
        {
          'permission_name': "Modifier les types de missions du cabinet",
          'collaborateur': false,
          'manager': false,
          'admin': true,
          'superadmin': false
        },
        
        {
          'permission_name': "Modifier les conventions d'honoraires types de votre cabinet",
          'collaborateur': false,
          'manager': false,
          'admin': false,
          'superadmin': true
        },
        
        {
          'permission_name': "Modifier les informations de votre entreprise",
          'collaborateur': false,
          'manager': false,
          'admin': false,
          'superadmin': true
        },
        {
          'permission_name': "Gérer l'abonnement Eclerk du cabinet",
          'collaborateur': false,
          'manager': false,
          'admin': false,
          'superadmin': true
        },
      ],
      permissionsRenderFields: [
        { key: "permission_name", label: "Permission"},
        { key: "collaborateur", label: "COLLABORATEUR"},
        { key: "manager", label: "MANAGER"},
        { key: "admin", label: "ADMINISTRATEUR"},
        { key: "superadmin", label: "SUPER-ADMINISTRATEUR"},
      ],
    }
  },

}
</script>
